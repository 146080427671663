.App {
  text-align: center;
  /* margin-left: 64px;
  margin-right: 64px; */
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
